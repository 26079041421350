@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  background: #9a9a9a;
  background: -moz-linear-gradient(45deg, rgba(154, 154, 154, 1) 0%, rgba(190, 190, 190, 1) 33%, rgba(231, 231, 231, 1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(154, 154, 154, 1) 0%, rgba(190, 190, 190, 1) 33%, rgba(231, 231, 231, 1) 100%);
  background: linear-gradient(45deg, rgba(154, 154, 154, 1) 0%, rgba(190, 190, 190, 1) 33%, rgba(231, 231, 231, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9a9a9a", endColorstr="#e7e7e7", GradientType=1);
  background-size: 300% 300%;
  animation: waves 6s ease infinite;
  font-family: 'Epilogue', sans-serif;
}

@keyframes waves {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.techItem {
  transition: transform 1s
}

.techItem:hover {
  transform: scale(1.1) rotate(360deg);
}

.contactItem {
  transition: transform 0.5s
}

.contactItem:hover {
  transform: scale(1.1);
  font-weight: bold
}